import {
  Modal,
  ModalOverlay,
  ModalContent,
  Box,
  Spinner,
  Center,
  IconButton,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { useState } from 'react';
import { CloseCircleIcon } from '@instamojo/icons';
import { isNewCardFlow } from '../../utils';
import NewCheckoutPaymentModal from './NewCheckoutPaymentModal';

interface PaymentModalProps {
  isOpen: boolean;
  onClose: () => void;
  paymentLink: string;
  showCloseButton: boolean;
}

const PaymentModal: React.FC<PaymentModalProps> = ({ isOpen, onClose, paymentLink, showCloseButton }) => {
  const [isPaymentFrameLoading, setIsPaymentFrameLoading] = useState(true);
  const [isLargerThan600] = useMediaQuery('(min-width: 600px)');
  const handlePaymentIframeLoad = () => {
    setIsPaymentFrameLoading(false);
  };
  return (
    <>
      {(isNewCardFlow(paymentLink)) ? (
        <NewCheckoutPaymentModal paymentLink={paymentLink} isOpen={isOpen} onClose={onClose} isPaymentFrameLoading={isPaymentFrameLoading} handlePaymentIframeLoad={handlePaymentIframeLoad} />
      ) : (
        <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="outside"
        isCentered
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent width="320px">
          <Box position="relative">
            {isPaymentFrameLoading && (
              <Center
                pos="absolute"
                top="0"
                right="0"
                left="0"
                bottom="0"
                zIndex="10"
                bg="white"
                d="flex"
                flexDirection="column"
              >
                <Spinner size="sm" />
                <Box textAlign="center" mt="24px">
                  <Text fontSize="20" mb="8px">
                    Please wait
                  </Text>
                  <Text fontSize="16" mb="24px">
                    We are processing your order.
                  </Text>
                  <Text color="red" fontSize="10">
                    Do not press back or refresh this page
                  </Text>
                </Box>
              </Center>
            )}
            <Box overflow="hidden">
              <iframe
                src={`${paymentLink}`}
                title="paymentModal"
                width={isLargerThan600 ? '640px' : '100%'}
                style={{
                  height: 'calc(100vh - 60px)',
                  marginTop: '-65px',
                  position: 'relative',
                  left: isLargerThan600 ? '-160px' : '0',
                }}
                scrolling="yes"
                onLoad={handlePaymentIframeLoad}
              />
            </Box>
            {showCloseButton && (
              <IconButton
                pos="absolute"
                right="-32px"
                top="0"
                aria-label="close"
                icon={<CloseCircleIcon color="#a00" />}
                bgColor="white"
                size="sm"
                borderRadius="none"
                onClick={onClose}
              />
            )}
          </Box>
        </ModalContent>
      </Modal>
      )}
    </>
  );
};

export default PaymentModal;
